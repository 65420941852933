import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  Footer,
  MainHeader,
  InvestorSlider,
  BlogCardMain,
  ProfitCalculate,
} from "components";
import { FaRegCirclePlay } from "react-icons/fa6";

import useNavigate from "hooks/Navigate";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import apis from "services";
import Slider from "react-slick";

import cms from "services/cms";
import detectLang from "utils/detectLang";

import detectLangNested from "utils/detectLangNested";

import cmsImage from "utils/cmsImage";
import cmsVideos from "utils/cmsVideos";
import _html from "utils/_html";
import { toast } from "react-toastify";
import CustomHelmet from "../components/CustomHelmet";
import Question from "components/Question";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { getToken } from "firebase/messaging";
import { messaging } from ".././firebase";
import { setUser } from "redux/slices/userSlice";
import { setAdmin } from "redux/slices/adminSlice";

import homeSecSevenLeft from "../assets/images/home-sec-seven-left.png";
import homeSecSevenRight from "../assets/images/home-sec-seven-right.png";

import TestimonialCardSlider from "components/landingpage/TestimonialCardSlider";
import { ImCross } from "react-icons/im";

import { CircularProgress } from "@mui/material";

const settings = {
  autoplay: true,
  arrows: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const settings_blog = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const settings_new_blog = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const settings_3 = {
  autoplay: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  swipeToSlide: true,
  slidesToScroll: 1,
  nextArrow: <HiOutlineArrowNarrowRight />,
  prevArrow: <HiOutlineArrowNarrowLeft />,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settings_2 = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const LandingPage = () => {
  const { user } = useSelector((store) => store.user);
  const { adminUser } = useSelector((store) => store.admin);
  const { t } = useTranslation();
  const [type, setType] = useState(false);
  const slider = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const searchParams = new URLSearchParams(useLocation().search);
  let { id } = Object.fromEntries(searchParams);
  const navigate = useNavigate();

  const videoRef12 = useRef(null);
  const [isPlaying12, setIsPlaying12] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (hasInteracted) {
        const videoRect = videoRef12.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the video is at least partially visible on the screen
        if (videoRect.top < windowHeight && videoRect.bottom > 0) {
          if (!isPlaying12) {
            videoRef12.current.play();
            setIsPlaying12(true);
          }
        } else {
          if (isPlaying12) {
            videoRef12.current.pause();
            setIsPlaying12(false);
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isPlaying12, hasInteracted]);

  const handlePlay = () => {
    console.log("CLICK PLAY VIDEO");
    setHasInteracted(true);
    videoRef12.current.play();
    setIsPlaying12(true);
  };

  const handleMuteToggle = () => {
    videoRef12.current.muted = !videoRef12.current.muted;
  };

  const lang = localStorage.getItem("i18nextLng") || "sp";
  console.log(lang, "langlanglanglanglang");
  const [setting, setSetting] = useState({});
  const [section, setSection] = useState([]);

  const [active, setActive] = useState(false);

  const [active1, setActive1] = useState(false);

  const [isLoading, setisLoading] = useState(false);

  const [categories, setCategories] = useState([]);

  const [seos, setSeos] = useState({});

  const toggleActive = () => {
    setActive(!active);
  };

  const toggleActive1 = () => {
    setActive1(!active1);
  };
  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  let [newsFeet, setNewsFeet] = useState({ email: "" });

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setNewsFeet((prevState) => ({ ...prevState, [id]: value }));
  };

  const [contactus, setContactUs] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const onChangeContactUsHandler = (e) => {
    const { id, value } = e.target;
    setContactUs((prevState) => ({ ...prevState, [id]: value }));
  };

  let [blogs, setBlogs] = useState([]);

  const fetchData = async () => {
    const res = await apis.getTotalRecord();
    setData(res?.data);
  };

  const fetchBlogs = async () => {
    const res = await apis.getAllBlogs();
    setBlogs(res?.data?.blog);
  };
  const fetchSetting = async () => {
    try {
      setisLoading(true);
      const res = await cms.getSetting(
        "header,service_setting,join_setting,team,ceo,notice,thinking,footer,work,banner,footer,scope,what,join_setting,investor_pitch,video,investo,about,questions,know,what,number,benefit",
        "all,home"
      );
      console.log(res?.data, "RES DATA");
      setSetting(res?.data?.data);

      setisLoading(false);
    } catch (e) {
      setisLoading(false);
    }
  };
  const fetchSection = async () => {
    const res = await cms.getSection(
      "work,join_section,team,investor,social",
      "home,all"
    );
    setSection(res?.data?.data);
  };
  const fetchSeo = async () => {
    const res = await cms.getSeo("home");
    setSeos(res?.data?.data);
  };

  const fetchCategory = async () => {
    const res = await cms.getCategory();
    setCategories(res?.data?.data);
  };
  const submit = async (e) => {
    e.preventDefault();
    const res = await apis.addNewsFeet(newsFeet);
    if (res?.data?.status) {
      setNewsFeet({ email: "" });
      toast.success(res.data?.message);
    } else {
      toast.error(res.data?.message);
      setNewsFeet({ email: "" });
    }
  };

  const fetchToken = async () => {
    console.log(
      navigator.userAgent.indexOf("Chrome") != -1,
      "messagingmessaging"
    );
    if (navigator.userAgent.indexOf("Chrome") != -1) {
      return getToken(messaging, {
        vapidKey:
          // "BMpVypoBuKIlz3lr5Mal3KlJY2ozYfIGPBS1Hy0wwLYCh4a-XaVx0uRhKj0-a7YBtSO--qZyAQgiLWg62avaM1k",
          "BEi5L-bkWNbgKBKL3KBiymjzMl1q_rIzhKpl2IhRI8nwE5SMFH2kmx1fXgPSbopXuEZi-7Z804u3s33FVO9EyTY",
      })
        .then(async (currentToken) => {
          if (currentToken) {
            console.log("current token for client: ", currentToken);
            localStorage.setItem("not_token", currentToken);

            if (user) {
              const res = await apis.updateUserProfileToken(user?.id, {
                not_token: currentToken,
              });
              if (res?.data?.status) {
                dispatch(
                  setUser({ id: res?.data.user?._id, ...res?.data?.user })
                );
              }
            } else if (adminUser) {
              const res = await apis.updateAdminProfileToken(adminUser?._id, {
                not_token: currentToken,
              });
              if (res?.data?.status) {
                dispatch(setAdmin(res?.data?.admin));
              }
            }
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    }
  };
  useEffect(() => {
    (async () => {
      if (user || adminUser) {
        if (navigator.userAgent.indexOf("Chrome") != -1) {
          await fetchToken();
        }
      }

      await fetchSetting();
      await fetchSection();
      await fetchSeo();
      await fetchCategory();
      await fetchData();
      await fetchBlogs();
    })();
  }, []);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [isPlaying3, setIsPlaying3] = useState(false);
  const [isPlaying5, setIsPlaying5] = useState(false);

  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef5 = useRef(null);

  const togglePlay = () => {
    if (videoRef.current.paused || videoRef.current.ended) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const togglePlay1 = () => {
    if (videoRef1.current.paused || videoRef1.current.ended) {
      videoRef1.current.play();
      setIsPlaying1(true);
    } else {
      videoRef1.current.pause();
      setIsPlaying1(false);
    }
  };
  const togglePlay2 = () => {
    if (videoRef2.current.paused || videoRef2.current.ended) {
      videoRef2.current.play();
      setIsPlaying(true);
    } else {
      videoRef2.current.pause();
      setIsPlaying(false);
    }
  };

  const togglePlay3 = () => {
    if (videoRef3.current.paused || videoRef3.current.ended) {
      videoRef3.current.play();
      setIsPlaying3(true);
    } else {
      videoRef3.current.pause();
      setIsPlaying3(false);
    }
  };
  const togglePlay5 = () => {
    if (videoRef5.current.paused || videoRef5.current.ended) {
      videoRef5.current.play();
      setIsPlaying5(true);
    } else {
      videoRef5.current.pause();
      setIsPlaying5(false);
    }
  };
  const handleVideoEnded = () => {
    setIsPlaying(false);
  };
  const handleVideoEnded1 = () => {
    setIsPlaying1(false);
  };
  const handleVideoEnded3 = () => {
    setIsPlaying3(false);
  };
  const handleVideoEnded5 = () => {
    setIsPlaying5(false);
  };

  useEffect(() => {
    document
      .getElementById(id)
      ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
  }, [id]);

  const submitContactUs = async (e) => {
    e.preventDefault();
    setType(false);

    const res = await apis.contactUs(contactus);
    if (res?.status) {
      toast.success(res?.data?.message);
    }
  };

  console.log(setting, "kashan");

  console.log(isLoading, "isLoadingisLoadingisLoading");
  return (
    <>
      {/* {isLoading ? (
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
          <CircularProgress color="error" />
        </div>
      ) : ( */}
        <div
          className="landing-main"
          onClick={() => {
            if (showDropdown) setShowDropdown(!showDropdown);
          }}
        >
          {/* <div className="form-form">F<br/>O<br/>R<br/>M</div> */}

          <div className={`landing-page landing-main-page custom-scroll`}>
            <div className="scroll-icon-wrapper" onClick={handleScroll}>
              <i
                className="fa-solid fa-arrow-up"
                style={{ fontSize: "35px" }}
              ></i>
            </div>

            <div className="main-section-wrapper position-relative">
              <section className="main-section">
                <MainHeader
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
                />
                {seos && seos?.seos ? (
                  <CustomHelmet seo={seos?.seos} lang={lang} />
                ) : (
                  ""
                )}
              </section>
              <div
                className="home-sec-1"
                style={{
                  backgroundImage: `url(${cmsImage(setting?.banner?.banner)})`,
                }}
              >
                <div className="bg-overlay"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="home-sec-1-left">
                        <h1
                          dangerouslySetInnerHTML={{
                            __html: detectLang(
                              setting?.banner,
                              "heading",
                              lang
                            ),
                            // t("home_sec_1.title"),
                          }}
                        ></h1>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: detectLang(
                              setting?.banner,
                              "description",
                              lang
                            ),
                            // t("home_sec_1.title"),
                          }}
                        ></p>
                        {/* <p>{t("home_sec_1.desc")}</p> */}
                        <div className="button">
                          <button onClick={() => navigate("/franchise")}>
                            {t("home_sec_1.button_1")}
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9807 15.1979 10.9807 14.8021 11.2247 14.5581L15.1578 10.625H3.33334C2.98817 10.625 2.70834 10.3452 2.70834 10C2.70834 9.65482 2.98817 9.375 3.33334 9.375H15.1578L11.2247 5.44194C10.9807 5.19786 10.9807 4.80214 11.2247 4.55806Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                          {/* <button onClick={() => navigate("/contact-us")}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.00623 2.83908C6.40629 1.43903 8.76891 1.54546 9.74375 3.29223L10.2846 4.26133C10.9212 5.40202 10.6499 6.84125 9.718 7.78456C9.70558 7.80157 9.6398 7.89734 9.63161 8.06469C9.62115 8.27829 9.69701 8.77227 10.4624 9.53763C11.2275 10.3027 11.7214 10.3788 11.9351 10.3684C12.1026 10.3602 12.1984 10.2944 12.2154 10.282C13.1588 9.35008 14.598 9.07881 15.7387 9.7154L16.7078 10.2562C18.4545 11.2311 18.561 13.5937 17.1609 14.9938C16.412 15.7427 15.4163 16.4081 14.2461 16.4524C12.512 16.5182 9.63263 16.0703 6.78114 13.2189C3.92966 10.3674 3.48183 7.48801 3.54757 5.7539C3.59193 4.58373 4.25735 3.58797 5.00623 2.83908ZM8.65223 3.90139C8.15305 3.00694 6.81164 2.80144 5.89011 3.72297C5.24399 4.36909 4.82393 5.08227 4.79668 5.80125C4.74185 7.24737 5.09889 9.76883 7.66503 12.335C10.2312 14.9011 12.7526 15.2581 14.1988 15.2033C14.9177 15.1761 15.6309 14.756 16.277 14.1099C17.1986 13.1884 16.9931 11.8469 16.0986 11.3478L15.1295 10.8069C14.5267 10.4705 13.6799 10.5853 13.0855 11.1797L13.0853 11.1798C13.0269 11.2382 12.6554 11.5848 11.9958 11.6169C11.3206 11.6498 10.5034 11.3464 9.57849 10.4215C8.65331 9.49634 8.35004 8.67884 8.38311 8.00356C8.4154 7.34396 8.76225 6.97263 8.82029 6.91458L8.82032 6.91456C9.41475 6.32013 9.52951 5.47334 9.19307 4.8705L8.65223 3.90139Z"
                              fill="#252525"
                            />
                          </svg>
                          {t("home_sec_1.button_2")}
                        </button> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="home-sec-1home-sec-testimonial-right"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-sec-two">
                <div className="home-sec-twe-wrap">
                  <InvestorSlider />
                </div>
              </div>

              {/* <div className="home-sec-three">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6  my-auto">
                    <div className="home-sec-three-left">
                      <img src={homeSecThree} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="home-sec-three-right">
                      <h2>{t("home_sec_3.title")}</h2>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: t("home_sec_3.sub_title"),
                        }}
                      ></h3>
                      <p className="p1">{t("home_sec_3.desc_1")}</p>
                    
                      <div className="button">
                        <button onClick={() => navigate("/franchise")}>
                          {t("home_sec_1.button_1")}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9807 15.1979 10.9807 14.8021 11.2247 14.5581L15.1578 10.625H3.33334C2.98817 10.625 2.70834 10.3452 2.70834 10C2.70834 9.65482 2.98817 9.375 3.33334 9.375H15.1578L11.2247 5.44194C10.9807 5.19786 10.9807 4.80214 11.2247 4.55806Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              <div className="home-sec-three">
                <div className="container">
                  <span className="d-flex justify-content-center align-items-center mb-5 heading-partners">
                    {t("titles.investor-title", {
                      count: data?.totalInvestor ?? 0,
                    })}
                  </span>

                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="left">
                        <div
                          className="img-wrapper"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={cmsImage(setting?.scope?.image_1)}
                            onError={(e) =>
                              (e.currentTarget.src =
                                "/assets/images/sec3-icon1.png")
                            }
                            alt="profile-icon"
                            width="100px"
                            height="100px"
                            loading="lazy"
                          />
                        </div>
                        <p
                          className="p1"
                          dangerouslySetInnerHTML={{
                            __html: detectLangNested(
                              setting?.scope,
                              "heading",
                              lang,
                              1
                            ),
                          }}
                        ></p>
                        <p
                          className="p1"
                          dangerouslySetInnerHTML={{
                            __html: detectLangNested(
                              setting?.scope,
                              "description",
                              lang,
                              1
                            ),
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="center">
                        <div
                          className="img-wrapper"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={cmsImage(setting?.scope?.image_2)}
                            onError={(e) =>
                              (e.currentTarget.src =
                                "/assets/images/sec3-icon1.png")
                            }
                            alt="profile-icon"
                            width="100px"
                            height="100px"
                            loading="lazy"
                          />
                        </div>
                        <p
                          className="p1"
                          dangerouslySetInnerHTML={{
                            __html: detectLangNested(
                              setting?.scope,
                              "heading",
                              lang,
                              2
                            ),
                          }}
                        ></p>
                        {/* {console.log(setting?.scope?.description_en_2)} */}
                        <p
                          className="p1"
                          dangerouslySetInnerHTML={{
                            __html: detectLangNested(
                              setting?.scope,
                              "description",
                              lang,
                              2
                            ),
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="right">
                        <div
                          className="img-wrapper"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={cmsImage(setting?.scope?.image_3)}
                            onError={(e) =>
                              (e.currentTarget.src =
                                "/assets/images/sec3-icon1.png")
                            }
                            alt="profile-icon"
                            width="100px"
                            height="100px"
                            loading="lazy"
                          />
                        </div>
                        <p
                          className="p1"
                          dangerouslySetInnerHTML={{
                            __html: detectLangNested(
                              setting?.scope,
                              "heading",
                              lang,
                              3
                            ),
                          }}
                        ></p>
                        <p
                          className="p1"
                          dangerouslySetInnerHTML={{
                            __html: detectLangNested(
                              setting?.scope,
                              "description",
                              lang,
                              3
                            ),
                          }}
                        ></p>
                      </div>
                    </div>

                    <div className="button">
                      <button onClick={() => navigate("/franchise")}>
                        {t("home_sec_1.button_1")}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9807 15.1979 10.9807 14.8021 11.2247 14.5581L15.1578 10.625H3.33334C2.98817 10.625 2.70834 10.3452 2.70834 10C2.70834 9.65482 2.98817 9.375 3.33334 9.375H15.1578L11.2247 5.44194C10.9807 5.19786 10.9807 4.80214 11.2247 4.55806Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-sec-testimonial">
                <div className="container">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(
                        lang === "en"
                          ? setting?.video?.heading_en_1
                          : setting?.video?.heading_sp_1
                      ),
                      // detectLangNested(setting?.video,"heading",lang,1)
                    }}
                  ></div>

                  {/* <h1>{t("home_sec_testimonial.title")}</h1> */}
                  <div className="row">
                    {setting?.video && (
                      <TestimonialCardSlider
                        lang={lang}
                        data={setting?.video}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="home-sec-four">
                <div className="container">
                  <div className="row  gx-5">
                    <div className="col-lg-6">
                      <div className="home-sec-four-left">
                        <h2
                          dangerouslySetInnerHTML={{
                            __html: detectLang(
                              setting?.investor_pitch,
                              "question",
                              lang
                            ),
                          }}
                        ></h2>
                        {/* <h2>{t(lang === "en" ? setting?.investor_pitch?.eng_question : setting?.about?.sp_question )}</h2> */}

                        <h3
                          dangerouslySetInnerHTML={{
                            __html: detectLang(
                              setting?.investor_pitch,
                              "heading",
                              lang
                            ),
                          }}
                        ></h3>
                        <p className="p1">
                          {detectLang(
                            setting?.investor_pitch,
                            "description",
                            lang
                          )}
                        </p>
                        {/* <p className="p2">{t("home_sec_4.desc_2")}</p> */}
                        <div className="button">
                          <button onClick={() => navigate("/franchise")}>
                            {t("home_sec_4.button_1")}
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clip-rule="evenodd"
                                d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  my-auto">
                      <div className="home-sec-four-right">
                        <img
                          src={cmsImage(setting?.investor_pitch?.image)}
                          onError={(e) =>
                            (e.currentTarget.src =
                              "/assets/images/home-sec-four-sideImg.jpeg")
                          }
                          alt="profile-icon"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-sec-five">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: detectLangNested(
                            setting?.benefit,
                            "short",
                            lang,
                            "heading"
                          ),
                        }}
                      ></h2>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: detectLang(setting?.benefit, "heading", lang),
                        }}
                      ></h3>
                    </div>
                    <div className="col-lg-4">
                      <div className="left">
                        <div className="home-sec-five-left-one">
                          <div className="number">1</div>
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_1_heading_en
                                  : setting?.benefit?.benefit_1_heading_sp
                              ),
                            }}
                          ></h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_1_description_en
                                  : setting?.benefit?.benefit_1_description_sp
                              ),
                            }}
                          ></p>
                        </div>
                        <div className="home-sec-five-left-two">
                          <div className="number">3</div>
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_3_heading_en
                                  : setting?.benefit?.benefit_3_heading_sp
                              ),
                            }}
                          ></h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_3_description_en
                                  : setting?.benefit?.benefit_3_description_sp
                              ),
                            }}
                          ></p>
                        </div>
                        <div className="home-sec-five-left-three">
                          <div className="number">5</div>
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_5_heading_en
                                  : setting?.benefit?.benefit_5_heading_sp
                              ),
                            }}
                          ></h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_5_description_en
                                  : setting?.benefit?.benefit_5_description_sp
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="home-sec-five-center">
                        <img
                          src={cmsImage(setting?.benefit?.image)}
                          onError={(e) =>
                            (e.currentTarget.src =
                              "/assets/images/sec3-icon1.png")
                          }
                          alt="profile-icon"
                          loading="lazy"
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="right">
                        <div className="home-sec-five-right-one">
                          <div className="number">2</div>
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_2_heading_en
                                  : setting?.benefit?.benefit_2_heading_sp
                              ),
                            }}
                          ></h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_2_description_en
                                  : setting?.benefit?.benefit_2_description_en
                              ),
                            }}
                          ></p>
                        </div>
                        <div className="home-sec-five-right-two">
                          <div className="number">4</div>
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_4_heading_en
                                  : setting?.benefit?.benefit_4_heading_sp
                              ),
                            }}
                          ></h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_4_description_en
                                  : setting?.benefit?.benefit_4_description_sp
                              ),
                            }}
                          ></p>
                        </div>
                        <div className="home-sec-five-right-three">
                          <div className="number">6</div>
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_6_heading_en
                                  : setting?.benefit?.benefit_6_heading_sp
                              ),
                            }}
                          ></h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                lang === "en"
                                  ? setting?.benefit?.benefit_6_description_en
                                  : setting?.benefit?.benefit_6_description_sp
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-sec-six">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="home-sec-six-left">
                        <h2
                          dangerouslySetInnerHTML={{
                            __html: t(
                              lang === "en"
                                ? setting?.investo?.short_heading_en
                                : setting?.investo?.short_heading_sp
                            ),
                          }}
                        ></h2>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: t(
                              lang === "en"
                                ? setting?.investo?.long_heading_en
                                : setting?.investo?.long_heading_sp
                            ),
                          }}
                        ></h3>
                        <p className="p1">
                          {" "}
                          {detectLang(setting?.investo, "description", lang)}
                          {/* {t("home_sec_6.desc_1")} */}
                        </p>
                        {/* <p className="p2">{t("home_sec_6.desc_1")}</p> */}
                        <div className="button">
                          <button onClick={() => navigate("/franchise")}>
                            {t("home_sec_6.button_1")}
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 my-auto">
                      <div className="home-sec-six-right">
                        <img
                          src={cmsImage(setting?.investo?.image)}
                          onError={(e) =>
                            (e.currentTarget.src =
                              "/assets/images/sec3-icon1.png")
                          }
                          alt="profile-icon"
                          loading="lazy"
                        ></img>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-sec-seven">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: detectLang(setting?.what, "heading", lang),
                        }}
                      ></h2>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: detectLang(setting?.what, "question", lang),
                        }}
                      ></h3>
                    </div>
                    <div className="col-lg-8 mx-auto">
                      <div className="row">
                        <div className="col-lg-12">
                          {setting?.what?.video && (
                            <div className="video">
                              <video
                                ref={videoRef1}
                                onEnded={handleVideoEnded1}
                                onClick={togglePlay1}
                                // loop
                                playsinline={true}
                                width={"100%"}
                                height={"100%"}
                                style={{ opacity: isPlaying1 ? 1 : 0 }}
                                // controls
                              >
                                <source
                                  src={cmsVideos(setting?.what?.video)}
                                  // src={`${process.env.REACT_APP_SERVER_URL}upload/testimonialNew.mp4`}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>

                              <>
                                <div
                                  className="background"
                                  style={{ opacity: isPlaying1 ? 0 : 1 }}
                                >
                                  <img
                                    onClick={togglePlay1}
                                    src={cmsImage(setting?.what?.image)}
                                    // src="/assets/images/home-sec-sevenImg.png"
                                    alt=""
                                    loading="lazy"
                                  />
                                  <FaRegCirclePlay onClick={togglePlay1} />
                                </div>
                                {isPlaying1 && (
                                  <div
                                    className="close-icon-sec7"
                                    onClick={togglePlay1}
                                  >
                                    <ImCross />
                                  </div>
                                )}
                                <div className="left-img">
                                  <img
                                    src={homeSecSevenLeft}
                                    alt=""
                                    style={{ opacity: isPlaying1 ? "0" : "1" }}
                                    loading="lazy"
                                  />
                                </div>
                                <div className="right-img">
                                  <img
                                    src={homeSecSevenRight}
                                    alt=""
                                    style={{ opacity: isPlaying1 ? "0" : "1" }}
                                    loading="lazy"
                                  />
                                </div>
                              </>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 mx-auto">
                      {/* <h1 className="title">{t("home_sec_7.title")}</h1> */}
                      {/* <h1
                      dangerouslySetInnerHTML={{
                        __html: detectLang(setting?.what, "question", lang),
                      }}
                    ></h1> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: detectLang(
                            setting?.what,
                            "description",
                            lang
                          ),
                        }}
                      ></p>
                      {/* <p>{t("home_sec_7.para1")}</p><br/>
                    <p>{t("home_sec_7.para2")}</p><br/>
                    <p>{t("home_sec_7.para3")}</p> */}
                    </div>
                  </div>
                </div>
              </div>

              <div id="roi" className="home-sec-eight sec-five">
                <div className="home-sec-eight-bg"></div>
                <ProfitCalculate />
              </div>

              <div className="home-sec-ten">
                <div className="container">
                  <div className="col-12">
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: t(
                          lang === "en"
                            ? setting?.about?.heading_en
                            : setting?.about?.heading_sp
                        ),
                      }}
                    ></h1>
                    <p>
                      {t(
                        lang === "en"
                          ? setting?.about?.description_en
                          : setting?.about?.description_sp
                      )}
                    </p>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="left">
                            <div className="cardd">
                              <div className="right-con">
                                <img
                                  src={cmsImage(setting?.about?.mission_image)}
                                  onError={(e) =>
                                    (e.currentTarget.src =
                                      "/images/profile-icon.png")
                                  }
                                  alt="profile-icon"
                                  loading="lazy"
                                />
                              </div>
                              <div className="right-con">
                                <h3
                                  dangerouslySetInnerHTML={{
                                    __html: detectLangNested(
                                      setting?.about,
                                      "mission",
                                      lang,
                                      "heading"
                                    ),
                                    // t("home_sec_10.title_1"),
                                  }}
                                ></h3>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: detectLangNested(
                                      setting?.about,
                                      "mission",
                                      lang,
                                      "description"
                                    ),
                                    // t("home_sec_10.title_1"),
                                  }}
                                ></p>
                                {/* <p>{t("home_sec_10.desc_1")}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 my-auto">
                          <div className="right">
                            <div className="cardd">
                              <div className="right-con">
                                <img
                                  src={cmsImage(
                                    setting?.about?.milestone_image
                                  )}
                                  onError={(e) =>
                                    (e.currentTarget.src =
                                      "/images/profile-icon.png")
                                  }
                                  //  src="/images/profile-icon.png"
                                  alt="profile-icon"
                                  loading="lazy"
                                />
                              </div>
                              <div className="right-con">
                                <h3
                                  dangerouslySetInnerHTML={{
                                    __html: detectLangNested(
                                      setting?.about,
                                      "milestone",
                                      lang,
                                      "heading"
                                    ),

                                    // t("home_sec_10.title_2"),
                                  }}
                                ></h3>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: detectLangNested(
                                      setting?.about,
                                      "milestone",
                                      lang,
                                      "description"
                                    ),
                                    // t("home_sec_10.title_1"),
                                  }}
                                ></p>

                                {/* <p>{t("home_sec_10.desc_2")}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="button">
                        <button onClick={() => navigate("/franchise")}>
                          {t("home_sec_10.button_1")}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="home-sec-eleven">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 my-auto">
                      <div className="left">
                        {setting?.know?.video && (
                          <div className="video">
                            <video
                              ref={videoRef3}
                              onEnded={handleVideoEnded3}
                              onClick={togglePlay3}
                              // loop
                              playsinline={true}
                              width={"100%"}
                              height={"100%"}
                              style={{ opacity: isPlaying3 ? 1 : 0 }}
                              // controls
                            >
                              <source
                                src={cmsVideos(setting?.know?.video)}
                                // src={`${process.env.REACT_APP_SERVER_URL}upload/ANTONIOINTERVIEW.mp4`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                            <>
                              <div
                                className="background"
                                style={{ opacity: isPlaying3 ? 0 : 1 }}
                              >
                                <img
                                  onClick={togglePlay3}
                                  //  src={img1bg}
                                  src={cmsImage(setting?.know?.image)}
                                  alt=""
                                  loading="lazy"
                                />
                                {/* <FaRegCirclePlay onClick={togglePlay3} /> */}
                              </div>
                            </>
                          </div>
                        )}
                        {/* <div className="video">
                        <video
                          ref={videoRef2}
                          onEnded={handleVideoEnded}
                          onClick={togglePlay2}
                          loop
                          playsinline={true}
                        >
                          <source
                            src="/videos/ANTONIOINTERVIEW.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        {!isPlaying && (
                          <div className="background">
                            <img
                              onClick={togglePlay2}
                              src="/images/playPauseVideo.svg"
                              alt=""
                            />
                          </div>
                        )}
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 my-auto">
                      <div className="right">
                        <h1
                          dangerouslySetInnerHTML={{
                            __html: detectLang(setting?.know, "heading", lang),
                            //  t("home_sec_11.title"),
                          }}
                        ></h1>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: detectLang(
                              setting?.know,
                              "description",
                              lang
                            ),
                            //  t("home_sec_11.title"),
                          }}
                        ></p>
                        {/* <p>{t("home_sec_11.desc_1")}</p>
                      <p>{t("home_sec_11.desc_2")}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {setting?.number?.video && (
                <div className="home-sec-twelve">
                  <div className="video">
                    <video
                      ref={videoRef5}
                      onEnded={handleVideoEnded5}
                      onClick={togglePlay5}
                      playsinline={true}
                      width={"100%"}
                      height={"100%"}
                      style={{ opacity: isPlaying5 ? 1 : 0 }}
                    >
                      <source
                        src={cmsVideos(setting?.number?.video)}
                        // src={`${process.env.REACT_APP_SERVER_URL}upload/PRESSINTERVIEW.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    <>
                      <div
                        className="background"
                        style={{ opacity: isPlaying5 ? 0 : 1 }}
                      >
                        <img
                          onClick={togglePlay5}
                          // src={img2}
                          src={cmsImage(setting?.number?.image)}
                          // src="/assets/images/bg-img-back.png"
                          alt=""
                          style={{ width: "100%" }}
                          loading="lazy"
                        />
                        <FaRegCirclePlay onClick={togglePlay5} />
                      </div>
                    </>
                  </div>

                  <div className="bg-video">
                    {/* <button
               onClick={handlePlay}
               style={{ position: "relative", zIndex: "10" }}
             >
               Play Video
             </button>
             {hasInteracted && (
               <button
                 onClick={handleMuteToggle}
                 style={{ position: "relative", zIndex: "10" }}
               >
                 {videoRef12.current.muted ? "Unmute" : "Mute"}
               </button> 
             )} */}
                    <video
                      ref={videoRef12}
                      muted
                      playsinline
                      loop
                      className="bg-video-landing"
                      autoPlay
                      // poster="/assets/images/bg-img-back.png"
                      poster={cmsImage(setting?.number?.image)}
                    >
                      <source
                        src={cmsVideos(setting?.number?.video)}
                        // src="https://cms-investin.javatimescaffe.com/public/assets/videos/1719708933_pressinterview.mp4"
                        // src={`${process.env.REACT_APP_SERVER_URL}upload/PRESSINTERVIEW.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>

                    <div className="bg-overlay"></div>
                  </div>
                  <div className="data" id="hide-on-mobile">
                    <div className="content">
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: detectLang(setting?.number, "heading", lang),
                          //  t("home_sec_12.title")
                        }}
                      ></h1>
                      <div className="button">
                        <button onClick={() => navigate("/franchise")}>
                          {t("home_sec_12.button_1")}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="content-card">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-4 col-md-12 col-12 my-auto">
                            <div className="cardd">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card1",
                                    lang,
                                    "heading"
                                  ),
                                }}
                              ></h3>
                              <h1
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card_1",
                                    lang,
                                    "score"
                                  ),
                                }}
                              ></h1>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card1",
                                    lang,
                                    "description"
                                  ),
                                }}
                              ></p>
                              {/* <h3>{t("home_sec_12.title_one")}</h3>
                       <h1>{t("home_sec_12.sub_title_one")}</h1>
                       <p>{t("home_sec_12.desc_one")}</p> */}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-12 col-12  my-auto">
                            <div className="cardd">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card2",
                                    lang,
                                    "heading"
                                  ),
                                }}
                              ></h3>
                              <h1
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card2",
                                    lang,
                                    "score"
                                  ),
                                }}
                              ></h1>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card2",
                                    lang,
                                    "description"
                                  ),
                                }}
                              ></p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-12 col-12  my-auto">
                            <div className="cardd">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card3",
                                    lang,
                                    "heading"
                                  ),
                                }}
                              ></h3>
                              <h1
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card3",
                                    lang,
                                    "score"
                                  ),
                                }}
                              ></h1>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: detectLangNested(
                                    setting?.number,
                                    "card3",
                                    lang,
                                    "description"
                                  ),
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="data-0twelve">
                <div className="content">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: detectLang(setting?.number, "heading", lang),
                    }}
                  ></h1>
                  <div className="button"></div>
                </div>
                <div className="content-card">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-12 my-auto">
                        <div className="cardd">
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card1",
                                lang,
                                "heading"
                              ),
                            }}
                          ></h3>
                          <h1
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card_1",
                                lang,
                                "score"
                              ),
                            }}
                          ></h1>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card1",
                                lang,
                                "description"
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-12  my-auto">
                        <div className="cardd">
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card2",
                                lang,
                                "heading"
                              ),
                            }}
                          ></h3>
                          <h1
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card2",
                                lang,
                                "score"
                              ),
                            }}
                          ></h1>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card2",
                                lang,
                                "description"
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-12  my-auto">
                        <div className="cardd">
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card3",
                                lang,
                                "heading"
                              ),
                            }}
                          ></h3>
                          <h1
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card3",
                                lang,
                                "score"
                              ),
                            }}
                          ></h1>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: detectLangNested(
                                setting?.number,
                                "card3",
                                lang,
                                "description"
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button">
                  <button onClick={() => navigate("/franchise")}>
                    {t("home_sec_12.button_1")}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <section className="sec-ten">
              <div className="container-fluid">
                <div className=".sec-ten-wrap">
                  <h2
                    className="title"
                    dangerouslySetInnerHTML={{ __html: t("section_10.title") }}
                  ></h2>
                  {blogs?.length >= 3 ? (
                    <>
                      <Slider {...settings_new_blog}>
                        {blogs?.reverse()?.map((blog) => {
                          return (
                            <>
                              <BlogCardMain
                                id={blog._id}
                                slug={blog.slug}
                                title={blog.title}
                                img={blog.image}
                                desc={blog.description}
                                author={blog.author}
                                time={blog.createdAt}
                                authorImg={blog.authorImage}
                              />
                            </>
                          );
                        })}
                      </Slider>
                    </>
                  ) : (
                    <>
                      <Row>
                        {blogs?.reverse()?.map((blog) => {
                          return (
                            <>
                              <Col>
                                <BlogCardMain
                                  id={blog._id}
                                  slug={blog.slug}
                                  title={blog.title}
                                  img={blog.image}
                                  desc={blog.description}
                                  author={blog.author}
                                  time={blog.createdAt}
                                  authorImg={blog.authorImage}
                                />
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    </>
                  )}
                </div>
              </div>
            </section>

            <section className="sec-four">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 mx-auto">
                    <div>
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: detectLang(
                            setting?.questions,
                            "heading",
                            lang
                          ),
                        }}
                      ></h2>

                      <Question
                        title={detectLangNested(
                          setting?.questions,
                          "question",
                          lang,
                          "1"
                        )}
                        para={detectLangNested(
                          setting?.questions,
                          "answer",
                          lang,
                          "1"
                        )}
                      />
                      <Question
                        title={detectLangNested(
                          setting?.questions,
                          "question",
                          lang,
                          2
                        )}
                        para={detectLangNested(
                          setting?.questions,
                          "answer",
                          lang,
                          2
                        )}
                      />
                      <Question
                        title={detectLangNested(
                          setting?.questions,
                          "question",
                          lang,
                          3
                        )}
                        para={detectLangNested(
                          setting?.questions,
                          "answer",
                          lang,
                          3
                        )}
                      />
                      <Question
                        title={detectLangNested(
                          setting?.questions,
                          "question",
                          lang,
                          4
                        )}
                        para={detectLangNested(
                          setting?.questions,
                          "answer",
                          lang,
                          4
                        )}
                      />
                      <Question
                        title={detectLangNested(
                          setting?.questions,
                          "question",
                          lang,
                          5
                        )}
                        para={detectLangNested(
                          setting?.questions,
                          "answer",
                          lang,
                          5
                        )}
                      />
                      <Question
                        title={detectLangNested(
                          setting?.questions,
                          "question",
                          lang,
                          6
                        )}
                        para={detectLangNested(
                          setting?.questions,
                          "answer",
                          lang,
                          6
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Footer />
          </div>
        </div>
       {/* )} */}
    </>
  );
};

export default LandingPage;
