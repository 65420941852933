import {Box} from '@mui/material';
import {toast} from "react-toastify";
import {Container} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";

import useNavigate from "hooks/Navigate";
import apis from '../../services';
import CssBaseline from "@mui/material/CssBaseline";
import {AddStoreForm} from "../../components/forms";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNewSidebar from 'components/admin/AdminNewSidebar';
import { Header } from 'components';
import { useSelector } from 'react-redux';
import AddOfferForm from 'components/forms/AddOfferForm';



const INITIAL_FORM_STATE = {
    type: "",
    targetMinimumAmount: null,
    targetMaximumAmount: null,
    rewardPercentage: null,
  };

function AddUpdateOffer() {
    const [offerData, setOfferData] = useState(INITIAL_FORM_STATE);
    const {id: offerId} = useParams();
    
    const {adminUser} = useSelector((store) => store.admin);
    const navigate = useNavigate();
 
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setOfferData((prevState) => ({ ...prevState, [name]: value }));
    };

    const fetchOfferDetails = async (offerId) => {
        try {
            const res = await apis.getOffer(offerId);
            if (res.data.status) {
                let {_id,__v,updatedAt,createdAt,isActive,isDeleted,...rest}=res.data.offer;
                setOfferData(rest);
            }
        } catch (e) {
            console.error('ERROR in fetching offer details', e);
        }
    };

    const onCreateOfferHandler = async (data) => {
        try {
            const res = await apis.createOffer(data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                const redirect = adminUser?.type === "owner" ?  "/admin/offers" : "/sub-admin/offers";
                navigate(redirect);
                setOfferData(INITIAL_FORM_STATE);
            }
        } catch (e) {
            console.error('ERROR on Create Store', e);
            if(e?.message){
             toast.error(e?.message);   
            }
        }
    };

    const onUpdateOfferHandler = async (data) => {
        try {
            const res = await apis.updateOffer(data,offerId);
            if (res?.data?.status) {
                toast.success(res.data.message);
                const redirect = adminUser?.type === "owner" ?  "/admin/offers" : "/sub-admin/offers";
                navigate(redirect);
                setOfferData(INITIAL_FORM_STATE);
            }
        } catch (e) {
            console.error('ERROR on update Store', e);
        }
    };

    useEffect(() => {
        (async () => {
            if (offerId)
                await fetchOfferDetails(offerId);
        })();
    }, [offerId]);

    const onSubmitHandler = async (data) => {
        if (offerId) await onUpdateOfferHandler(offerData);
        else await onCreateOfferHandler(offerData);
    };



    return (
        <Box className="dashboard-main">
            <AdminNewSidebar />
            <div className="dashboard-section-main">
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                    className="custom-scroll"
                >
                    <Header />
                    <Container>
                        <h3 className="section-title dark-text text-start mb-0">{offerId ? 'Update' : 'Add'} Offer</h3>
                        <AddOfferForm
                            formData={offerData}
                            onChangeHandler={onChangeHandler}
                            submitHandler={onSubmitHandler}
                            heading={offerId ? 'Update' : 'Add'}
                            offerId={offerId}
                        />
                    </Container>
                </Box>
            </div>
        </Box>
    );
}

export default AddUpdateOffer;
